header {
  /* max-width: 2000px; */
  width: 100%;
  background-image: url(../images/nav_bgc.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

nav.menu {
  width: 100%;
}

div.header {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.logo {
  width: auto;
  height: 95px;
  padding: 10px;
}

ul.menu {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}

li.menu,
li.nav {
  display: flex;
  flex-grow: 1;
  flex-basis: 10%;
  justify-content: center;
}

a.nav {
  display: flex;
  color: black;
  padding: 15px 20px;
  font-size: var(--fz-nav);
  text-decoration: none;
  transition: 1s ease;
  text-transform: uppercase;
  opacity: 0.5;
  font-weight: bold;
  align-self: center;
}

a.logo .menu {
  align-self: center;
}

a.active {
  opacity: 1;
}

a.nav:hover {
  opacity: 1;
}

/* dropdown menu */

li.hamburger {
  display: none;
}

button.burger {
  position: relative;
  display: flex;
  color: black;
  padding: 15px 20px;
  font-size: 25px;
  background-color: transparent;
  text-decoration: none;
  transition: 1s ease;
  text-transform: uppercase;
  font-weight: bold;
  align-self: center;
  border: none;
}
button.burger:focus {
  outline: none;
}

div.dropdown {
  display: flex;
  position: absolute;
  top: 81px;
  width: auto;
  padding: 15px;
  transform: translateX(-50%);
  background-color: var(--ham-nav-bgc);
  border-radius: var(--ham-nav-border-radius);
  z-index: 999;
  animation: dropdown 0.6s ease;
}

ul.dropdown {
  list-style: none;
}

a.dropdown {
  color: var(--ham-nav-color);
  text-decoration: none;
}

a.dropdown .active {
  background-color: var(--ham-nav-active);
}

button.active {
  opacity: 1;
}

@media (max-width: 1340px) {
  div.header {
    max-width: 900px;
  }
  a.nav {
    font-size: 21px;
  }
}

@media (max-width: 990px) {
  div.header {
    max-width: 700px;
  }
  a.nav {
    font-size: 19px;
    padding: 0px;
  }
  img.logo {
    height: 90px;
  }
}

@media (max-width: 740px) {
  div.header {
    padding-left: 2%;
    padding-right: 2%;
  }

  img.logo {
    height: 85px;
  }
}

@media (max-width: 690px) {
  a.nav {
    font-size: 17px;
  }
}
@media (max-width: 640px) {
  img.logo {
    height: 80px;
  }
}

/* hamburger */

@media (max-width: 590px) {
  li.menu {
    display: none;
  }
  li.logo {
    display: flex;
  }
  li.hamburger {
    display: flex;
    opacity: 0.95;
    z-index: 5;
  }

  div.dropdown {
    opacity: 0;
    z-index: 10;
  }

  a.active {
    opacity: 1;
  }
  a.dropdown {
    font-size: 20px;
  }

  img.logo {
    height: 90px;
  }

  button.burger {
    margin-left: 40px;
    font-size: 40px;
  }
}

@media (max-width: 470px) {
  div.dropdown {
    top: 72px;
  }

  button.burger {
    font-size: 35px;
  }
  a.dropdown {
    font-size: 18px;
  }

  img.logo {
    height: 85px;
  }
}

@keyframes dropdown {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
