footer {
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0;
  background-color: var(--footer-backgroundcolor);
  font-size: 15px;
  text-decoration: none;
}

div.footerWrapper div {
  flex-basis: 23%;
  margin: 10px;
}

div.footerWrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.FAdres section,
.FData p,
.FTelephone p,
.FEmail p {
  background-color: var(--footer-bgc);
  padding: 10px;
  margin-top: 5px;
  border-radius: 15px;
  color: var(--footer-p-color);
}

footer h1 {
  font-size: 20px;
  color: var(--footer-p-color);
}

span.hours {
  font-weight: bold;
}

div.FWrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  background-color: rgb(24, 21, 21);
  font-size: 12px;
}

p.by {
  display: flex;
  font-size: 0.7rem;
  padding: 10px;
  color: white;
}

a.privacy {
  text-transform: capitalize;
  display: flex;
  font-size: 0.7rem;
  padding: 10px;
  color: white;
}

a.FAdres svg,
p.FData svg,
p.FTelephone svg,
p.FEmail svg {
  color: black;
  margin-right: 5px;
  font-size: 15px;
}

@media (max-width: 1340px) {
  div.footerWrapper {
    font-size: 14px;
  }
  p.FAdres svg,
  p.FData svg,
  p.FTelephone svg,
  p.FEmail svg {
    font-size: 14px;
  }

  footer h1 {
    font-size: 18px;
  }
  div.footerWrapper div {
    flex-basis: 45%;
  }
}

@media (max-width: 990px) {
  div.footerWrapper div {
    flex-basis: 45%;
  }
  div.footerWrapper {
    font-size: 13px;
  }
  p.FAdres svg,
  p.FData svg,
  p.FTelephone svg,
  p.FEmail svg {
    font-size: 13px;
  }
}

@media (max-width: 740px) {
  div.footerWrapper div {
    flex-basis: 40%;
  }
  div.footerWrapper {
    align-content: center;
    justify-content: center;
  }
}

@media (max-width: 620px) {
  div.footerWrapper {
    font-size: 14px;
  }
  div.footerWrapper div {
    flex-basis: 90%;
  }
  div.FWrapper {
    flex-direction: column;
  }
  a.privacy {
    align-self: center;
    padding: 0 0 10px 0;
  }
  div.FAdres,
  div.FData,
  div.FTelephone,
  div.FEmail {
    align-content: center;
    justify-content: center;
  }
  section.FAdres,
  p.FData,
  p.FTelephone,
  p.FEmail {
    font-size: 14px;
    text-align: center;
  }
}
